import React from 'react'

export default function Ebook_Under_Construction_page() {
    return (
        <div>

            <img src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/under-construction-sign-design-template-70502891e7dc676869296d8e513f4f7d_screen.jpg?ts=1649753826" />

        </div>
    )
}
