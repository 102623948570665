import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { FaRegPaperPlane } from "react-icons/fa";
import { database } from '../firebase';
import moment from 'moment';
import { v4 as uuid } from "uuid";
import { BsXLg } from "react-icons/bs";
import { IoClose } from 'react-icons/io5';
import { toggleVisibility } from '../redux/reducers/toggleBottomSheet.js';

const BottomSheet = ({ id }) => {
  const [datas, setDatas] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const videoId = id;
  const chatContainerRef = useRef(null);
  const user = useSelector((s) => s.user.user);
  const inputRef = useRef();
  const image = user.avatar;
  const [replyMessage, setReplyMessage] = useState(null);
  const [replyMode, setReplyMode] = useState(false);
  const [Moderator, setModerator] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const chatRef = database.ref('chatMessages');
    chatRef
      .orderByChild('GroupId')
      .equalTo(videoId)
      .on('value', (snapshot) => {
        const messages = [];
        snapshot.forEach((childSnapshot) => {
          const message = childSnapshot.val();
          messages.push(message);
        });

        if (messages.length > 150) {

          const slicedMessages = messages.slice(-150);
          setDatas(slicedMessages);
        } else {
          setDatas(messages);
        }
      });

    return () => {
      chatRef.off('value');
    };
  }, [videoId]);

  const handleReply = (originalMessage) => {
    setReplyMode(true);

    // Extract relevant information from the clicked message


    // Set the extracted information in the replyMessage state


    // Log the replyMessage

    if (inputRef.current) {
      inputRef.current.focus();
    }


    setReplyMessage(originalMessage);
    console.log(originalMessage);

  };


  const fetchData = async () => {
    try {
      const userId = user._id;
      console.log("userId....", userId);
      const response = await axios.get('https://api.competitioncommunity.com/user/getModeratorStatus', {
        params: {
          userId: userId
        }
      });
      console.log("request made..", response.data);
      if (response.data.isModerator === true) {
        setModerator(true);
        console.log()
      } else {
        setModerator(false);
      }
    } catch (error) {
      console.error("Error:", error);

    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = (data) => {
    const { messageId } = data;
    try {
      const chatRef = database.ref('chatMessages'); // Use database.ref directly, assuming database is the Firebase database instance
      chatRef
        .orderByChild('messageId')
        .equalTo(messageId)
        .once('value', (snapshot) => {
          snapshot.forEach((childSnapshot) => {
            // Remove the message
            chatRef.child(childSnapshot.key).remove();
          });
        });

      // Update the UI by removing the deleted message
      setDatas((prevData) => prevData.filter((item) => item.messageId !== messageId));

    } catch (error) {
      console.error('Error occurred while deleting the message:', error);
    }
  };


  useEffect(() => {

    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [datas]);

  const handleChatButtonPress = async () => {
    if (inputValue) {
      try {
        await database.ref("chatMessages").push({
          messageId: uuid(),
          userName: user?.name,
          GroupId: videoId,
          message: inputValue,
          timestamp: Date.now(),
          avatar: image || null,
          repliedMessage: replyMode ? replyMessage : null,
        });




      } catch (error) {
        console.error('Error occurred while adding message:', error);
      }
    }

    setInputValue('');
    setReplyMessage(null);
    setReplyMode(false);
  };






  const getSheetStyles = () => ({
    width: '30%',
    height: '100%',
    position: 'fixed',
    right: 0,
    bottom: 0,
    backgroundColor: '#F0F0F0',
    zIndex: 1,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: "#ffff",
    "-webkit-box-shadow": "-2px 0px 5px 0px rgba(0,0,0,0.75)",
    "-moz-box-shadow": "-2px 0px 5px 0px rgba(0,0,0,0.75)",
    "box-shadow": "-2px 0px 5px 0px rgba(0,0,0,0.75)"
  });

  const getHeaderStyle = () => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center', // Center vertically
    color: 'red',
    padding: '10px',
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '1rem',
    wordSpacing: '4px',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.75)',
  });

  const getChatContainerStyle = () => ({
    flex: 1,
    overflowY: 'auto',
    padding: '10px 5px',
    marginTop: ".25rem",
    backgroundColor: "#d3d3d3",
    overflowX: 'hidden',
    whiteSpace: 'pre-line',
    maxWidth: '100%'

  });

  const getMessageContainer = () => ({
    marginBottom: "1rem",
    display: 'flex',
    flexDirection: 'column', // Align "Reply" and "Hide" vertically
    // overflow: 'hidden'
    marginBottom: '2.5rem'
  });

  const getMessageStyle = () => ({
    display: 'inline-block',
    backgroundColor: 'white',
    color: '#333333',
    padding: '5px 10px',
    borderRadius: '0 10px 10px 10px',

    // Push the message to the right
    maxWidth: '92%',
    width: 'fit-content'
    // wordWrap: 'break-word',

  });

  const getDisplayNameStyle = () => ({
    marginRight: '8px',
    fontSize: "1rem",
    color: "#666968"
  });

  const getMessageTextStyle = () => ({
    fontSize: '14px',
    width: 'fit-content'
  });

  const getInputContainerStyle = () => ({
    backgroundColor: '#fff',
    padding: '10px 20px',
    borderTop: '1px solid #CCC',
    marginTop: 'auto',
    position: "relative"
  });

  const getInputSection = () => ({
    display: 'flex',
    alignItems: 'center',
  })

  const getInputStyle = () => ({
    flex: 1,
    padding: '10px',
    border: '1px solid #CCC',
    borderRadius: '5px',
    marginRight: '10px',
    fontSize: '14px',
    outline: "none",
    lineHeight: 1.5,
    // position: 'relative',
    whiteSpace: 'break-spaces',

    wordWrap: 'break-word',
  });

  const getSendButtonStyle = () => ({
    width: "1.5rem",
    height: "1.5rem",
    color: "red",
    cursor: "pointer",
    // alignItems: "flex-end",
    // position: 'absolute',
    // right: '2.5rem'
  });
  const getClearButtonStyle = () => ({
    cursor: 'pointer',
    fontSize: '16px',
    color: 'gray',
    marginLeft: 'auto',
    dispaly: 'block'
  });


  const getAvatarStyle = (hasImage) => ({
    minWidth: '45px',
    maxWidth: '45px',
    height: '45px',
    borderRadius: '50%',
    marginRight: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: hasImage ? 'transparent' : 'green', // Use transparent background if there is an image, otherwise green
    color: hasImage ? 'inherit' : 'white', // Use the default text color if there is an image, otherwise white
  });
  const formatTimestamp = (timestamp) => {
    return moment(timestamp).fromNow();
  };

  const getAvatarInitial = (userName) => {
    if (userName && userName.length > 0) {
      return userName[0].toUpperCase();
    }
    return "";
  };
  const handleChatContainerClick = (e) => {
    e.stopPropagation();
  };
  const handleClearButtonClick = () => {
    setInputValue('');
    setReplyMode(false);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const toggle = () => {
    dispatch(toggleVisibility());
  }

  return (
    <div style={getSheetStyles()}>
      <div style={getHeaderStyle()}>
        <span className="headerText">Comments</span>
        <IoClose
          onClick={toggle}
          size={50}
          style={{ cursor: 'pointer' }}
        />

      </div>

      <div style={getChatContainerStyle()} ref={chatContainerRef} onClick={handleChatContainerClick} >
        {datas.map((data, index) => (
          <div key={data.timestamp} style={getMessageContainer()}>
            <div style={{ display: 'flex', marginBottom: '.5rem' }}>
              {data.avatar ? (
                <img src={data.avatar} style={getAvatarStyle(true)} alt={`Avatar of ${data.userName}`} />
              ) : (
                <div style={getAvatarStyle(false)}>
                  {getAvatarInitial(data.userName)}
                </div>
              )}
              <div style={{ maxWidth: '35rem' }}>
                <h5 style={getDisplayNameStyle()}>{`@${data.userName.toLowerCase().replace(/\s/g, '')},${formatTimestamp(data.timestamp)}`}</h5>
                {data.repliedMessage && (
                  <div style={{
                    backgroundColor: '#40414f', color: 'aliceblue', padding: '5px 10px', borderRadius: '0 10px 10px 10px', marginTop: '5px', marginBottom: '1rem', maxWidth: '92%'
                  }}>
                    < p > {data.repliedMessage.userName}</p>
                    <p style={{ wordWrap: 'break-word', }}>{data.repliedMessage.message}</p>
                  </div>
                )}
                <div key={index} style={getMessageStyle()}>
                  <span style={{ wordWrap: 'break-word', width: 'fit-content', fontWeight: 'bold' }}>{data.message}</span>
                </div>
                <div style={{ display: 'flex', gap: '1rem', justifyContent: 'start', }}>
                  {Moderator && (
                    <span onClick={() => handleReply(data)} style={{ cursor: 'pointer' }}>
                      Reply
                    </span>
                  )}

                  {Moderator && (
                    <span onClick={() => handleDelete(data)} style={{ color: "red", cursor: 'pointer' }}>
                      Hide
                    </span>
                  )}
                </div>
              </div>
            </div>

          </div>
        ))
        }
      </div >

      <div style={getInputContainerStyle()}>
        {replyMode && replyMessage && (
          <div>
            <span style={getClearButtonStyle()} onClick={handleClearButtonClick}>
              <BsXLg />
            </span>
            <p style={{ color: 'green', }}>Replying to:

              <span style={{ color: 'black', marginLeft: '.5rem' }}>
                {replyMessage.userName}
              </span></p>
            <p style={{ marginBottom: '.5rem' }}>{replyMessage.message}</p>
          </div>


        )}


        <div style={getInputSection()}>
          <textarea
            style={getInputStyle()}
            ref={inputRef}
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Type a message..."
          />

          <FaRegPaperPlane style={getSendButtonStyle()} onClick={handleChatButtonPress} />
        </div>
      </div>
    </div >
  );
}

export default BottomSheet;