import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isVisible: false,
};

const bottomSheetSlice = createSlice({
    name: 'bottomSheet',
    initialState,
    reducers: {
        toggleVisibility: (state) => {
            state.isVisible = !state.isVisible;
        },
    },
});

export const { toggleVisibility } = bottomSheetSlice.actions;

export const bottomSheetReducer = bottomSheetSlice.reducer;
